@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}
.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

body {
  background: linear-gradient(75.32deg, #090979 3.83%, #15a2ff 62.01%, #2395ff 97.21%);
  min-height: 100vh;
  font-family: "IBM Plex Sans", sans-serif;
}

h1 {
  font-size: 3rem;
  color: white;
  font-weight: 400;
}

h2 {
  font-size: 1.875rem;
  color: #0A81F5;
}

h3 {
  font-size: 1.25rem;
  color: white;
}

p {
  font-size: 1rem;
  color: white;
}

legend {
  font-size: 0.8125em;
}

#page {
  display: flex;
}

#content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 100vh;
  max-width: 100vw;
}

.sidebar {
  padding: 25px 10px !important;
  position: fixed;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-btn {
  color: white;
  background-color: transparent;
  border: none;
}

.page-text {
  background-color: #fff;
  padding: 3rem;
  border-radius: 15px;
}

.btn-icon {
  padding: 0;
  justify-content: center;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
  height: 30px;
  width: 30px;
}

.lang-select {
  color: white;
}

.btn-icon .feather {
  margin-top: 0 !important;
}

.btn .feather {
  margin-top: -1px;
  height: 0.875rem;
  width: 0.875rem;
}

.pagination {
  flex-wrap: wrap !important;
}

.main-page {
  transition: all 0.5s;
  padding-top: 1.5rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
}

.actions-div {
  display: flex;
  justify-content: space-around;
}

.md-hover {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0.5em;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.md-hover:hover {
  background-color: #e7e7e7;
}

@media (min-width: 1200px) {
  .container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    max-width: 1000px;
  }
}
@media (max-width: 992px) {
  .container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    max-width: 960px;
  }

  h1 {
    font-size: 2rem;
  }

  .sidebar {
    display: none;
  }

  .footer {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  #content {
    margin-left: 0;
  }
}
.react-datepicker-popper {
  z-index: 4 !important;
}

.ed-modal.aviso .modal-header {
  background-color: #FFC75B;
  color: white;
}

body .dark {
  background-image: url(/static/media/fundonoc.6f0f3d69.jpg) !important;
  background-size: cover !important;
  color: #B1B1B1 !important;
}

.dark .big-card, .dark .ed-tabela {
  background-color: #252525 !important;
  color: #B1B1B1 !important;
}
.dark div.big-card input, .dark select {
  background-color: #3B3B3B !important;
  color: #C0C0C0 !important;
  border-color: #C0C0C0 !important;
}
.dark #sidebar, .dark .dropzone, .dark .modal-body {
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
}
.dark .sidebar-content > li > .sidebar-link p, .dark .sidebar-content .sidebar-icon, .dark h2, .dark .sidebar-content .sidebar-link .arrow, .dark .sidebar-content .sub-menu li a, .dark .sidebar-content .sub-menu li, .dark .sidebar-content p, .dark .sidebar-content a, .dark .ed-tabela td p, .dark .modal-header h1, .dark .modal-header svg {
  color: #B1B1B1 !important;
}
.dark .sub-item a:hover, .dark .sidebar-content .sidebar-link:hover, .dark .sidebar-content > li > .sub-menu > .sub-item:hover > a, .dark .sidebar-content .sub-menu .sub-item .sub-menu li:hover {
  color: white !important;
  background-color: black !important;
}

.right_sidebar {
  display: flex;
  flex-direction: column;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1rem;
  text-align: center;
}
.right_sidebar > :not(:first-child) {
  border-top: 1px solid white;
}

.right_sidebar .dropleft {
  display: flex;
  justify-content: center;
}

.right_sidebar .avatar img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
}

.right_sidebar .right_sidebar__item {
  padding: 1rem 0;
}
.right_sidebar .right_sidebar__item .right_sidebar__icon {
  height: 3rem;
  width: 3rem;
}
.right_sidebar .right_sidebar__item p {
  margin-top: 0.3rem;
  margin-bottom: 0;
  font-weight: 600;
}

.right_sidebar .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}

.right_sidebar .dropdown-menu .dropdown-item:active p {
  color: white;
}

.right_sidebar .dropdown-menu .dropdown-item svg {
  margin-right: 10px;
}

.right_sidebar .dropdown-menu .dropdown-item p {
  margin: 0;
  color: black;
}

.right_sidebar .dropdown-menu .dropdown-item button {
  background-color: transparent;
  color: #69707a;
  border: none;
  padding: 0;
  font-size: 0.9rem;
}

.right_sidebar .dropdown-menu .dropdown-item .dropdown-item-icon {
  margin-right: 0.5rem;
  line-height: 1;
}

.right_sidebar.mobile {
  display: none;
  flex-direction: row;
  position: static;
  margin: 0;
  padding: 0;
}

.right_sidebar.mobile .right_sidebar__item {
  border: none;
  padding: 0;
  margin-right: 1rem;
}
.right_sidebar.mobile .right_sidebar__item .right_sidebar__icon {
  height: 2.5rem;
  width: 2.5rem;
}
.right_sidebar.mobile .right_sidebar__item .right_sidebar__title {
  display: none;
}
.right_sidebar.mobile .right_sidebar__item .avatar img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .right_sidebar {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .right_sidebar.mobile {
    display: flex;
  }
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar {
  background: #fff;
  color: #0586D7;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  left: -250px;
  position: fixed;
  /* color: #212832; */
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  top: 0;
  transition: all 0.3s;
  width: 250px;
  z-index: 2001;
}

#sidebar::-webkit-scrollbar, .main-sidebar::-webkit-scrollbar {
  display: none;
}

#sidebar.active {
  left: 0;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

#dismiss {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

#dismiss:hover {
  background: #fff;
  color: #0586D7;
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.overlay.active {
  display: block;
  opacity: 1;
}

.main-sidebar {
  background-color: #fafafa;
  box-shadow: 0.15rem 0 1.75rem 0 rgba(33, 40, 50, 0.15);
  color: #a7aeb8;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  justify-content: space-between;
  left: 0;
  position: fixed;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  transition: all 0.3s;
  width: 250px;
  z-index: 99;
}

.chamado {
  padding: 1em;
  margin-bottom: 1em;
}

.chamado button {
  width: 100%;
}

.sidebar-content {
  padding: 20px 0;
  color: #212832;
  margin-bottom: 0 !important;
}

.sidebar-content .sub-item a,
.sidebar-content .sub-item button {
  font-size: 0.95em;
  padding-left: 1.2rem;
  font-weight: 400;
}

.sidebar-content > li > .sidebar-link p {
  color: #0586D7;
  font-size: 1rem;
  font-weight: 600;
}

.sidebar-content > li > .sub-menu > .sub-item:hover > a {
  color: #0586D7;
  background-color: #fff;
}

.sidebar-content .sidebar-link:hover p, .sidebar-content .sidebar-link:hover .sidebar-icon, .sidebar-content .sidebar-link:hover .arrow {
  color: #0586D7;
}

.sidebar-content p {
  margin: 0;
  transition: color 0.3s;
}

.sidebar-content p, .sidebar-content a {
  color: #69707a;
}

.sidebar-content p:hover, .sidebar-content a:hover {
  color: #0586D7;
}

.sidebar-content a {
  font-size: 1.1em;
  font-weight: 400;
  display: block;
  cursor: pointer;
}

.sidebar-content .sidebar-icon {
  margin-right: 1rem;
  color: #0586D7;
  transition: color 0.3s;
}

.sidebar-content button {
  color: inherit;
  border: none;
  outline: none;
  background-color: transparent;
}

a[data-toggle=collapse] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.sub-menu.first_level {
  padding-left: 2.2rem;
}

.sub-menu.bordered {
  margin-left: 20px;
  border-left: 1px solid #d4dae3;
}

.sidebar-content .sub-menu li {
  transition: color 0.3s;
  color: #212832;
}
.sidebar-content .sub-menu li a, .sidebar-content .sub-menu li button {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.sidebar-content .sub-menu .sub-item .sub-menu li {
  transition: all 0.3s;
}

.sidebar-content .sub-menu .sub-item .sub-menu li:hover {
  background-color: #fff;
  color: #0586D7;
}

.sidebar-content .sidebar-link {
  display: flex;
  align-items: center;
  line-height: normal;
  padding: 0.9rem;
  padding-left: 1.1rem;
  position: relative;
  width: 100%;
  transition: all 0.3s;
}

.sidebar-content .sidebar-link.bordered {
  border-top: 1px solid #D8D8D8;
}

.sidebar-content .sidebar-link:hover {
  background-color: white;
}

.sidebar-content .sidebar-link .arrow {
  display: inline-block;
  margin-left: auto;
  transition: color 0.3s, -webkit-transform 0.15s ease;
  transition: transform 0.15s ease, color 0.3s;
  transition: transform 0.15s ease, color 0.3s, -webkit-transform 0.15s ease;
  color: #0586D7;
}

.sidebar-content .sidebar-link.collapsedSide .arrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.sidenav-menu-heading {
  padding: 0 1rem 0;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #a7aeb8;
}

@media (max-width: 992px) {
  .main-sidebar {
    left: -100%;
  }
}
@media (max-width: 576px) {
  .main-sidebar {
    display: none;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.5s;
  padding: 1em;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.footer p {
  margin: 0;
  color: white;
  margin-left: 15px;
}

#logoutButton {
  cursor: pointer;
}

.user-button h6 {
  flex-shrink: 0;
}

.user-dropdown p {
  margin: 3px 0 0 0;
}

.navbar {
  padding: 15px 10px !important;
  margin-left: 60px;
  background: transparent;
  border: none;
  border-radius: 0;
  color: white;
}

.navbar .menu-btn {
  display: none;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.navbar .navbar-brand {
  font-weight: bold;
  color: white;
  display: flex;
}
.navbar .navbar-brand img {
  height: 30px;
}

.user-button {
  display: flex;
  align-items: center;
}

.user-button > h6 {
  margin: 0 10px 0 0;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.navbar .dropstart .dropleft > button::before {
  display: none;
}

.navbar select {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}
.navbar select option {
  color: black;
  cursor: pointer;
}

.navbar select:focus {
  outline: none;
  box-shadow: none;
  background-color: transparent;
  color: white;
}

@media (max-width: 576px) {
  .user-button h6 {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .navbar {
    margin-left: 0;
  }

  .navbar .menu-btn {
    display: block;
    padding: 0.5rem;
  }

  .navbar .navbar-brand a {
    display: none;
  }
}
div.big-card {
  padding: 0;
  color: #69707a;
  border-radius: 25px;
}

div.big-card .card-header {
  padding: 20px;
  color: #0061f2;
}

div.big-card .card-body {
  padding: 2rem 3.5rem;
}

div.big-card input {
  color: #52575f;
}

@media screen and (max-width: 992px) {
  div.big-card .card-body {
    padding: 1rem 1.5rem;
  }
}
.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.invalid {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-field .tox-tinymce {
  border-color: #dc3545;
}

.invalid-field .dropzone {
  border-color: #dc3545 !important;
}

.modalAviso .modal-header {
  background-color: #ffc107 !important;
  color: white;
}

.modalErro .modal-header {
  background-color: #dc3545 !important;
  color: white;
}

.modalFb .modal-content {
  border: none;
}

.modalFb .modal-header .modal-title {
  display: flex;
  align-items: center;
}

.modalFb .modal-header .modal-title svg {
  margin-right: 10px;
}

.title-control {
  display: flex;
  align-items: center;
}

.title-control svg {
  margin-left: 10px;
  color: #007bff;
}

.add_widget {
  width: 100%;
}

.form-group {
  scroll-margin: 100px 0 0 0;
}

.form-group.added {
  -webkit-animation: flash 2s;
          animation: flash 2s;
}

@-webkit-keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #ffc107;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #ffc107;
  }
  100% {
    background-color: transparent;
  }
}
#header {
  text-align: right;
  color: white;
}

.page_header {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page_header__back button {
  background-color: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
}

.page_header h1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}

.page_header__icon {
  color: white;
  font-size: 0.75em;
  margin-left: 0.5rem;
  display: inline-flex;
}

.page_header p {
  font-size: 1.1rem;
  color: white;
  margin-bottom: 0;
}

@media screen and (max-width: 992px) {
  .page_header p {
    display: none;
  }
}
.tl-table textarea {
  min-width: 200px;
}

.tl-table .title-row {
  min-width: 200px;
  display: flex;
}

.tl-table .td-first {
  vertical-align: middle;
}

.tl-table thead {
  font-size: 0.875rem;
  border: none;
}

.tl-table thead tr {
  border: none;
}

.tl-table thead tr th {
  font-weight: 500;
  border: none;
}

.tl-table td.td-actions {
  vertical-align: middle;
}

.tl-table td .delete {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 50%;
}

.tl-table td .delete:hover {
  background-color: #e7e7e7;
}

.ed-tabela {
  background-color: white;
  color: #757575;
}
.ed-tabela td {
  padding: 1rem;
}
.ed-tabela td.texto {
  max-width: 10rem;
}
.ed-tabela th {
  border-top: none !important;
}
.ed-tabela .ed-tabela__sort__icon {
  display: none;
}
.ed-tabela .ed-tabela__sort__icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ed-tabela .ed-tabela__sort__icon svg.rotate {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.ed-tabela .ed-tabela__sort__icon.active {
  display: inline;
}
.ed-tabela .ed-tabela__sort:hover .ed-tabela__sort__icon {
  display: inline;
}
.ed-tabela svg {
  color: #0586D7;
}
.ed-tabela .circulo {
  border-radius: 50%;
  color: white;
  padding: 3px;
}
.ed-tabela .circulo.ativo {
  background-color: #48C864;
}
.ed-tabela .circulo.inativo {
  background-color: #FF6767;
}
.ed-tabela img {
  max-height: 4rem;
}
.ed-tabela .center {
  text-align: center;
  vertical-align: middle;
}
.ed-tabela .action {
  width: 6.5rem;
}
.ed-tabela p {
  margin: 0;
  font-size: 0.8rem;
  color: #757575;
}
.table-bordered tr td:first-child,
.table-bordered tr th:first-child {
  border-left: none;
}
.table-bordered tr td:last-child,
.table-bordered tr th:last-child {
  border-right: none;
}

.ed-tabela__acoes {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  align-items: flex-end;
  justify-content: space-between;
  overflow-x: auto;
  padding: 0.25rem 0;
}
.ed-tabela__acoes svg {
  color: #0586D7;
}
.ed-tabela__acoes .ed-tabela__pesquisa {
  display: flex;
  align-items: center;
}
.ed-tabela__acoes .ed-tabela__pesquisa input {
  min-width: 7rem;
}
.ed-tabela__acoes .ed-tabela__filtros {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.ed-tabela__acoes .ed-tabela__filtros > * {
  margin-top: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas {
  display: flex;
  margin-right: 1rem;
  align-items: flex-end;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas > *:not(:first-child) {
  margin-left: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas .data {
  width: 7.5rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__status {
  margin-right: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__status select {
  min-width: 6.25rem;
}
.ed-tabela__acoes .ed-tabela__filtros p {
  margin: 0;
  color: #757575;
}
.ed-tabela__acoes .ed-tabela__filtros .add-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ed-tabela__acoes .ed-tabela__filtros .add-button svg {
  height: 38px;
  width: 38px;
}

.ed-tabela__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ed-tabela__pagination input {
  width: 3rem;
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.ed-tabela__notFound {
  text-align: center;
  padding: 2rem;
}

@media screen and (max-width: 992px) {
  .ed-tabela__acoes {
    margin-bottom: 1rem;
  }

  .ed-tabela .hide-mobile {
    display: none;
  }
  .ed-tabela img {
    max-height: 3rem;
    max-width: 100%;
  }
  .ed-tabela td {
    text-overflow: ellipsis;
    max-width: 8rem;
    overflow: hidden;
    white-space: nowrap;
  }
}
.custom_modal.success .modal-header {
  background-color: #48C864;
}
.custom_modal.success .modal-header svg {
  color: #48C864;
}
.custom_modal.success .modal-body hr {
  border-top: 1px solid rgba(72, 200, 100, 0.5);
}
.custom_modal.success .modal-body button {
  color: #48C864;
}
.custom_modal.success .modal-body button:active {
  border: 1px solid #48C864;
  color: #48C864 !important;
  border-color: #48C864;
}
.custom_modal.success .custom_modal__options .line_divisoria {
  background-color: rgba(72, 200, 100, 0.5);
}

.custom_modal.warning .modal-header {
  background-color: #FFC75B;
}
.custom_modal.warning .modal-header svg {
  color: #FFC75B;
}
.custom_modal.warning .modal-body hr {
  border-top: 1px solid rgba(255, 199, 91, 0.5);
}
.custom_modal.warning .modal-body button {
  color: #FFC75B;
}
.custom_modal.warning .modal-body button:active {
  border: 1px solid #FFC75B;
  color: #FFC75B !important;
  border-color: #FFC75B;
}
.custom_modal.warning .custom_modal__options .line_divisoria {
  background-color: rgba(255, 199, 91, 0.5);
}

.custom_modal.error .modal-header {
  background-color: #FF6767;
}
.custom_modal.error .modal-header svg {
  color: #FF6767;
}
.custom_modal.error .modal-body hr {
  border-top: 1px solid rgba(255, 103, 103, 0.5);
}
.custom_modal.error .modal-body button {
  color: #FF6767;
}
.custom_modal.error .modal-body button:active {
  border: 1px solid #FF6767;
  color: #FF6767 !important;
  border-color: #FF6767;
}
.custom_modal.error .custom_modal__options .line_divisoria {
  background-color: rgba(255, 103, 103, 0.5);
}

.custom_modal .modal-content {
  border: none;
}

.custom_modal .modal-header {
  color: white;
  justify-content: flex-start;
  align-items: center;
}
.custom_modal .modal-header h1 {
  margin: 0;
}
.custom_modal .modal-header svg {
  background-color: white;
  border-radius: 50%;
  padding: 0.25rem;
  margin-right: 1rem;
}

.custom_modal .modal-body p {
  color: #838383;
  font-weight: 500;
}
.custom_modal .modal-body hr {
  width: 80%;
}
.custom_modal .modal-body button {
  background-color: transparent;
  border: none;
  display: block;
  margin: auto;
}
.custom_modal .modal-body button:active {
  background-color: transparent !important;
}

.custom_modal .custom_modal__options {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.custom_modal .custom_modal__options .line_divisoria {
  width: 1px;
  height: 30px;
}

.gray {
  color: #838383 !important;
}

.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.dark p {
  color: #B1B1B1 !important;
}

big-card p {
  color: #212529 !important;
}

.dark textarea {
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
}

.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.trello-card {
  width: 400px;
  height: auto;
  display: flex;
  border: 1px solid #B1B1B1;
  background-color: white !important;
  color: #3B3B3B !important;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}
.trello-card .trello-nome {
  font-weight: 500;
  font-size: 16px;
}
.trello-card .trello-nomeLista {
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
}
.trello-card .trello-desc {
  font-size: 14px;
  text-align: justify;
  color: #3B3B3B !important;
}
.trello-card .trello-datas {
  width: 180px;
  font-size: 12px;
  margin-left: 15px;
}
.trello-card .tags {
  align-items: end;
  justify-content: start;
  display: flex;
  flex-direction: column;
  width: 120px;
}
.trello-card .tags div {
  margin-bottom: 5px;
}
.trello-card .tags .red {
  width: 100px;
  height: 33px;
  background-color: rgba(220, 53, 69, 0.3);
  padding: 0.25rem 0.75rem;
  color: #dc3545;
  border-radius: 15px;
}
.trello-card .tags .red::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
}
.trello-card .tags .yellow {
  width: 100px;
  height: 33px;
  background-color: rgba(206, 168, 2, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cea802;
  border-radius: 15px;
}
.trello-card .tags .yellow::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cea802;
}
.trello-card .tags .purple {
  width: 110px;
  height: 33px;
  background-color: rgba(128, 0, 128, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cf02cf;
  border-radius: 15px;
}
.trello-card .tags .purple::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cf02cf;
}
.trello-card .tags .green {
  width: auto;
  height: auto;
  background-color: rgba(0, 128, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: green;
  border-radius: 15px;
}
.trello-card .tags .green::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: green;
}
.trello-card .tags .orange {
  width: auto;
  height: auto;
  background-color: rgba(255, 165, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: orange;
  border-radius: 15px;
}
.trello-card .tags .orange::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: orange;
}
.trello-card .tags .blue {
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 255, 0.3);
  padding: 0.25rem 0.75rem;
  color: #5555fd;
  border-radius: 15px;
}
.trello-card .tags .blue::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555fd;
}

.none, .pesq {
  color: #3B3B3B;
  width: 100%;
  margin: 15px auto;
  text-align: center;
}

.dark {
  color: #B1B1B1;
}
.dark .none, .dark .pesq {
  color: #B1B1B1;
  width: 100%;
}
.dark textarea {
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
}
.dark .trello-card {
  width: 400px;
  height: auto;
  display: flex;
  border: 1px solid #B1B1B1;
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}
.dark .trello-card .trello-nome {
  font-weight: 500;
  font-size: 16px;
}
.dark .trello-card .trello-desc {
  font-size: 14px;
  text-align: justify;
  color: #B1B1B1 !important;
}
.dark .trello-card .trello-datas {
  width: 180px;
  font-size: 12px;
  margin-left: 15px;
}
.dark .trello-card .tags {
  align-items: end;
  justify-content: start;
  display: flex;
  flex-direction: column;
  width: 120px;
}
.dark .trello-card .tags div {
  margin-bottom: 5px;
}
.dark .trello-card .tags .red {
  width: 100px;
  height: 33px;
  background-color: rgba(220, 53, 69, 0.3);
  padding: 0.25rem 0.75rem;
  color: #dc3545;
  border-radius: 15px;
}
.dark .trello-card .tags .red::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
}
.dark .trello-card .tags .yellow {
  width: 100px;
  height: 33px;
  background-color: rgba(255, 208, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: #ffd000;
  border-radius: 15px;
}
.dark .trello-card .tags .yellow::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffd000;
}
.dark .trello-card .tags .purple {
  width: 110px;
  height: 33px;
  background-color: rgba(128, 0, 128, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cf02cf;
  border-radius: 15px;
}
.dark .trello-card .tags .purple::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cf02cf;
}
.dark .trello-card .tags .green {
  width: auto;
  height: auto;
  background-color: rgba(0, 128, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: green;
  border-radius: 15px;
}
.dark .trello-card .tags .green::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: green;
}
.dark .trello-card .tags .orange {
  width: auto;
  height: auto;
  background-color: rgba(255, 165, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: orange;
  border-radius: 15px;
}
.dark .trello-card .tags .orange::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: orange;
}
.dark .trello-card .tags .blue {
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 255, 0.3);
  padding: 0.25rem 0.75rem;
  color: #5555fd;
  border-radius: 15px;
}
.dark .trello-card .tags .blue::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555fd;
}

.aviso {
  font-weight: 400;
  font-size: 1.2rem;
  color: #dc3545;
  width: 100%;
  margin: 15px auto;
  text-align: center;
}
.aviso b {
  font-weight: 600;
}
#loginContainer {
  min-height: 100vh;
}

.login__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
}

.login__logo {
  max-height: 175px;
  margin-bottom: 2rem;
  max-width: 80vw;
}

#loginCard {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: linear-gradient(241.81deg, rgba(86, 194, 255, 0.7) 0%, rgba(0, 43, 67, 0.07) 100%, rgba(20, 130, 191, 0.07) 100%);
  border: 1px solid rgba(255, 255, 255, 0.74);
  box-sizing: border-box;
  box-shadow: 1px 9px 16px 2px rgba(0, 0, 0, 0.18);
  -webkit-backdrop-filter: blur(43px);
          backdrop-filter: blur(43px);
  color: white;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 55px;
  padding: 2rem 2.5rem;
  max-width: 700px;
  min-height: 250px;
}
#loginCard .card-header {
  background: none;
}
#loginCard .title {
  margin-bottom: 1rem;
}

.loginRow {
  height: 360px;
  margin-top: 10vw;
}

.loginRow > div {
  padding: 0;
}

.loginRow .card {
  border-radius: 0;
}

.dogeWrap {
  overflow: hidden;
  height: 360px;
}

.dogeWrap img {
  max-height: 100%;
}

.submit_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.submit_row button {
  display: block;
  width: 100%;
  background-color: #148DED;
  color: white;
  border-radius: 23px;
  max-width: 300px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.submit_row .error {
  margin-left: 10px;
  font-size: 80%;
  color: #ff7482;
}

#loginform {
  max-width: 500px;
  margin: auto;
}

#loginform .title {
  display: flex;
  align-items: center;
  margin: 0;
}

#loginform .title svg {
  color: white;
  margin-right: 10px;
  margin-left: 10px;
}

#loginform .form-group {
  display: flex;
  justify-content: center;
}

#loginform input {
  border-radius: 20px;
}

#loginform .forgot {
  font-size: small;
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

#loginform .forgot a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  #loginCard {
    padding: 2rem 1.5rem !important;
  }

  .loginRow > div {
    padding: 15px;
  }

  #loginform .form-group {
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  #loginform .form-group .form-label {
    margin-bottom: 0.5rem;
  }

  .resetform label {
    width: 100%;
  }
}
.ed-modal.aviso .modal-header {
  background-color: #FFC75B;
  color: white;
}

.ed-tabela {
  background-color: white;
  color: #757575;
}
.ed-tabela td {
  padding: 1rem;
}
.ed-tabela td.texto {
  max-width: 10rem;
}
.ed-tabela th {
  border-top: none !important;
}
.ed-tabela .ed-tabela__sort__icon {
  display: none;
}
.ed-tabela .ed-tabela__sort__icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ed-tabela .ed-tabela__sort__icon svg.rotate {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.ed-tabela .ed-tabela__sort__icon.active {
  display: inline;
}
.ed-tabela .ed-tabela__sort:hover .ed-tabela__sort__icon {
  display: inline;
}
.ed-tabela svg {
  color: #0586D7;
}
.ed-tabela .circulo {
  border-radius: 50%;
  color: white;
  padding: 3px;
}
.ed-tabela .circulo.ativo {
  background-color: #48C864;
}
.ed-tabela .circulo.inativo {
  background-color: #FF6767;
}
.ed-tabela img {
  max-height: 4rem;
}
.ed-tabela .center {
  text-align: center;
  vertical-align: middle;
}
.ed-tabela .action {
  width: 6.5rem;
}
.ed-tabela p {
  margin: 0;
  font-size: 0.8rem;
  color: #757575;
}
.table-bordered tr td:first-child,
.table-bordered tr th:first-child {
  border-left: none;
}
.table-bordered tr td:last-child,
.table-bordered tr th:last-child {
  border-right: none;
}

.ed-tabela__acoes {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  align-items: flex-end;
  justify-content: space-between;
  overflow-x: auto;
  padding: 0.25rem 0;
}
.ed-tabela__acoes svg {
  color: #0586D7;
}
.ed-tabela__acoes .ed-tabela__pesquisa {
  display: flex;
  align-items: center;
}
.ed-tabela__acoes .ed-tabela__pesquisa input {
  min-width: 7rem;
}
.ed-tabela__acoes .ed-tabela__filtros {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.ed-tabela__acoes .ed-tabela__filtros > * {
  margin-top: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas {
  display: flex;
  margin-right: 1rem;
  align-items: flex-end;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas > *:not(:first-child) {
  margin-left: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas .data {
  width: 7.5rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__status {
  margin-right: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__status select {
  min-width: 6.25rem;
}
.ed-tabela__acoes .ed-tabela__filtros p {
  margin: 0;
  color: #757575;
}
.ed-tabela__acoes .ed-tabela__filtros .add-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ed-tabela__acoes .ed-tabela__filtros .add-button svg {
  height: 38px;
  width: 38px;
}

.ed-tabela__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ed-tabela__pagination input {
  width: 3rem;
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.ed-tabela__notFound {
  text-align: center;
  padding: 2rem;
}

@media screen and (max-width: 992px) {
  .ed-tabela__acoes {
    margin-bottom: 1rem;
  }

  .ed-tabela .hide-mobile {
    display: none;
  }
  .ed-tabela img {
    max-height: 3rem;
    max-width: 100%;
  }
  .ed-tabela td {
    text-overflow: ellipsis;
    max-width: 8rem;
    overflow: hidden;
    white-space: nowrap;
  }
}
.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.invalid {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-field .tox-tinymce {
  border-color: #dc3545;
}

.invalid-field .dropzone {
  border-color: #dc3545 !important;
}

.modalAviso .modal-header {
  background-color: #ffc107 !important;
  color: white;
}

.modalErro .modal-header {
  background-color: #dc3545 !important;
  color: white;
}

.modalFb .modal-content {
  border: none;
}

.modalFb .modal-header .modal-title {
  display: flex;
  align-items: center;
}

.modalFb .modal-header .modal-title svg {
  margin-right: 10px;
}

.title-control {
  display: flex;
  align-items: center;
}

.title-control svg {
  margin-left: 10px;
  color: #007bff;
}

.add_widget {
  width: 100%;
}

.form-group {
  scroll-margin: 100px 0 0 0;
}

.form-group.added {
  -webkit-animation: flash 2s;
          animation: flash 2s;
}

@-webkit-keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #ffc107;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #ffc107;
  }
  100% {
    background-color: transparent;
  }
}
#header {
  text-align: right;
  color: white;
}

.page_header {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page_header__back button {
  background-color: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
}

.page_header h1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}

.page_header__icon {
  color: white;
  font-size: 0.75em;
  margin-left: 0.5rem;
  display: inline-flex;
}

.page_header p {
  font-size: 1.1rem;
  color: white;
  margin-bottom: 0;
}

@media screen and (max-width: 992px) {
  .page_header p {
    display: none;
  }
}
.tl-table textarea {
  min-width: 200px;
}

.tl-table .title-row {
  min-width: 200px;
  display: flex;
}

.tl-table .td-first {
  vertical-align: middle;
}

.tl-table thead {
  font-size: 0.875rem;
  border: none;
}

.tl-table thead tr {
  border: none;
}

.tl-table thead tr th {
  font-weight: 500;
  border: none;
}

.tl-table td.td-actions {
  vertical-align: middle;
}

.tl-table td .delete {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 50%;
}

.tl-table td .delete:hover {
  background-color: #e7e7e7;
}

div.big-card {
  padding: 0;
  color: #69707a;
  border-radius: 25px;
}

div.big-card .card-header {
  padding: 20px;
  color: #0061f2;
}

div.big-card .card-body {
  padding: 2rem 3.5rem;
}

div.big-card input {
  color: #52575f;
}

@media screen and (max-width: 992px) {
  div.big-card .card-body {
    padding: 1rem 1.5rem;
  }
}
.custom_modal.success .modal-header {
  background-color: #48C864;
}
.custom_modal.success .modal-header svg {
  color: #48C864;
}
.custom_modal.success .modal-body hr {
  border-top: 1px solid rgba(72, 200, 100, 0.5);
}
.custom_modal.success .modal-body button {
  color: #48C864;
}
.custom_modal.success .modal-body button:active {
  border: 1px solid #48C864;
  color: #48C864 !important;
  border-color: #48C864;
}
.custom_modal.success .custom_modal__options .line_divisoria {
  background-color: rgba(72, 200, 100, 0.5);
}

.custom_modal.warning .modal-header {
  background-color: #FFC75B;
}
.custom_modal.warning .modal-header svg {
  color: #FFC75B;
}
.custom_modal.warning .modal-body hr {
  border-top: 1px solid rgba(255, 199, 91, 0.5);
}
.custom_modal.warning .modal-body button {
  color: #FFC75B;
}
.custom_modal.warning .modal-body button:active {
  border: 1px solid #FFC75B;
  color: #FFC75B !important;
  border-color: #FFC75B;
}
.custom_modal.warning .custom_modal__options .line_divisoria {
  background-color: rgba(255, 199, 91, 0.5);
}

.custom_modal.error .modal-header {
  background-color: #FF6767;
}
.custom_modal.error .modal-header svg {
  color: #FF6767;
}
.custom_modal.error .modal-body hr {
  border-top: 1px solid rgba(255, 103, 103, 0.5);
}
.custom_modal.error .modal-body button {
  color: #FF6767;
}
.custom_modal.error .modal-body button:active {
  border: 1px solid #FF6767;
  color: #FF6767 !important;
  border-color: #FF6767;
}
.custom_modal.error .custom_modal__options .line_divisoria {
  background-color: rgba(255, 103, 103, 0.5);
}

.custom_modal .modal-content {
  border: none;
}

.custom_modal .modal-header {
  color: white;
  justify-content: flex-start;
  align-items: center;
}
.custom_modal .modal-header h1 {
  margin: 0;
}
.custom_modal .modal-header svg {
  background-color: white;
  border-radius: 50%;
  padding: 0.25rem;
  margin-right: 1rem;
}

.custom_modal .modal-body p {
  color: #838383;
  font-weight: 500;
}
.custom_modal .modal-body hr {
  width: 80%;
}
.custom_modal .modal-body button {
  background-color: transparent;
  border: none;
  display: block;
  margin: auto;
}
.custom_modal .modal-body button:active {
  background-color: transparent !important;
}

.custom_modal .custom_modal__options {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.custom_modal .custom_modal__options .line_divisoria {
  width: 1px;
  height: 30px;
}

.gray {
  color: #838383 !important;
}

.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.dark p {
  color: #B1B1B1 !important;
}

big-card p {
  color: #212529 !important;
}

.dark textarea {
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
}

.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.trello-card {
  width: 400px;
  height: auto;
  display: flex;
  border: 1px solid #B1B1B1;
  background-color: white !important;
  color: #3B3B3B !important;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}
.trello-card .trello-nome {
  font-weight: 500;
  font-size: 16px;
}
.trello-card .trello-nomeLista {
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
}
.trello-card .trello-desc {
  font-size: 14px;
  text-align: justify;
  color: #3B3B3B !important;
}
.trello-card .trello-datas {
  width: 180px;
  font-size: 12px;
  margin-left: 15px;
}
.trello-card .tags {
  align-items: end;
  justify-content: start;
  display: flex;
  flex-direction: column;
  width: 120px;
}
.trello-card .tags div {
  margin-bottom: 5px;
}
.trello-card .tags .red {
  width: 100px;
  height: 33px;
  background-color: rgba(220, 53, 69, 0.3);
  padding: 0.25rem 0.75rem;
  color: #dc3545;
  border-radius: 15px;
}
.trello-card .tags .red::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
}
.trello-card .tags .yellow {
  width: 100px;
  height: 33px;
  background-color: rgba(206, 168, 2, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cea802;
  border-radius: 15px;
}
.trello-card .tags .yellow::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cea802;
}
.trello-card .tags .purple {
  width: 110px;
  height: 33px;
  background-color: rgba(128, 0, 128, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cf02cf;
  border-radius: 15px;
}
.trello-card .tags .purple::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cf02cf;
}
.trello-card .tags .green {
  width: auto;
  height: auto;
  background-color: rgba(0, 128, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: green;
  border-radius: 15px;
}
.trello-card .tags .green::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: green;
}
.trello-card .tags .orange {
  width: auto;
  height: auto;
  background-color: rgba(255, 165, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: orange;
  border-radius: 15px;
}
.trello-card .tags .orange::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: orange;
}
.trello-card .tags .blue {
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 255, 0.3);
  padding: 0.25rem 0.75rem;
  color: #5555fd;
  border-radius: 15px;
}
.trello-card .tags .blue::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555fd;
}

.none, .pesq {
  color: #3B3B3B;
  width: 100%;
  margin: 15px auto;
  text-align: center;
}

.dark {
  color: #B1B1B1;
}
.dark .none, .dark .pesq {
  color: #B1B1B1;
  width: 100%;
}
.dark textarea {
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
}
.dark .trello-card {
  width: 400px;
  height: auto;
  display: flex;
  border: 1px solid #B1B1B1;
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}
.dark .trello-card .trello-nome {
  font-weight: 500;
  font-size: 16px;
}
.dark .trello-card .trello-desc {
  font-size: 14px;
  text-align: justify;
  color: #B1B1B1 !important;
}
.dark .trello-card .trello-datas {
  width: 180px;
  font-size: 12px;
  margin-left: 15px;
}
.dark .trello-card .tags {
  align-items: end;
  justify-content: start;
  display: flex;
  flex-direction: column;
  width: 120px;
}
.dark .trello-card .tags div {
  margin-bottom: 5px;
}
.dark .trello-card .tags .red {
  width: 100px;
  height: 33px;
  background-color: rgba(220, 53, 69, 0.3);
  padding: 0.25rem 0.75rem;
  color: #dc3545;
  border-radius: 15px;
}
.dark .trello-card .tags .red::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
}
.dark .trello-card .tags .yellow {
  width: 100px;
  height: 33px;
  background-color: rgba(255, 208, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: #ffd000;
  border-radius: 15px;
}
.dark .trello-card .tags .yellow::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffd000;
}
.dark .trello-card .tags .purple {
  width: 110px;
  height: 33px;
  background-color: rgba(128, 0, 128, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cf02cf;
  border-radius: 15px;
}
.dark .trello-card .tags .purple::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cf02cf;
}
.dark .trello-card .tags .green {
  width: auto;
  height: auto;
  background-color: rgba(0, 128, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: green;
  border-radius: 15px;
}
.dark .trello-card .tags .green::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: green;
}
.dark .trello-card .tags .orange {
  width: auto;
  height: auto;
  background-color: rgba(255, 165, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: orange;
  border-radius: 15px;
}
.dark .trello-card .tags .orange::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: orange;
}
.dark .trello-card .tags .blue {
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 255, 0.3);
  padding: 0.25rem 0.75rem;
  color: #5555fd;
  border-radius: 15px;
}
.dark .trello-card .tags .blue::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555fd;
}

.aviso {
  font-weight: 400;
  font-size: 1.2rem;
  color: #dc3545;
  width: 100%;
  margin: 15px auto;
  text-align: center;
}
.aviso b {
  font-weight: 600;
}
.ed-modal.aviso .modal-header {
  background-color: #FFC75B;
  color: white;
}

div.big-card {
  padding: 0;
  color: #69707a;
  border-radius: 25px;
}

div.big-card .card-header {
  padding: 20px;
  color: #0061f2;
}

div.big-card .card-body {
  padding: 2rem 3.5rem;
}

div.big-card input {
  color: #52575f;
}

@media screen and (max-width: 992px) {
  div.big-card .card-body {
    padding: 1rem 1.5rem;
  }
}
#header {
  text-align: right;
  color: white;
}

.page_header {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page_header__back button {
  background-color: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
}

.page_header h1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}

.page_header__icon {
  color: white;
  font-size: 0.75em;
  margin-left: 0.5rem;
  display: inline-flex;
}

.page_header p {
  font-size: 1.1rem;
  color: white;
  margin-bottom: 0;
}

@media screen and (max-width: 992px) {
  .page_header p {
    display: none;
  }
}
.tl-table textarea {
  min-width: 200px;
}

.tl-table .title-row {
  min-width: 200px;
  display: flex;
}

.tl-table .td-first {
  vertical-align: middle;
}

.tl-table thead {
  font-size: 0.875rem;
  border: none;
}

.tl-table thead tr {
  border: none;
}

.tl-table thead tr th {
  font-weight: 500;
  border: none;
}

.tl-table td.td-actions {
  vertical-align: middle;
}

.tl-table td .delete {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 50%;
}

.tl-table td .delete:hover {
  background-color: #e7e7e7;
}

.ed-tabela {
  background-color: white;
  color: #757575;
}
.ed-tabela td {
  padding: 1rem;
}
.ed-tabela td.texto {
  max-width: 10rem;
}
.ed-tabela th {
  border-top: none !important;
}
.ed-tabela .ed-tabela__sort__icon {
  display: none;
}
.ed-tabela .ed-tabela__sort__icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ed-tabela .ed-tabela__sort__icon svg.rotate {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.ed-tabela .ed-tabela__sort__icon.active {
  display: inline;
}
.ed-tabela .ed-tabela__sort:hover .ed-tabela__sort__icon {
  display: inline;
}
.ed-tabela svg {
  color: #0586D7;
}
.ed-tabela .circulo {
  border-radius: 50%;
  color: white;
  padding: 3px;
}
.ed-tabela .circulo.ativo {
  background-color: #48C864;
}
.ed-tabela .circulo.inativo {
  background-color: #FF6767;
}
.ed-tabela img {
  max-height: 4rem;
}
.ed-tabela .center {
  text-align: center;
  vertical-align: middle;
}
.ed-tabela .action {
  width: 6.5rem;
}
.ed-tabela p {
  margin: 0;
  font-size: 0.8rem;
  color: #757575;
}
.table-bordered tr td:first-child,
.table-bordered tr th:first-child {
  border-left: none;
}
.table-bordered tr td:last-child,
.table-bordered tr th:last-child {
  border-right: none;
}

.ed-tabela__acoes {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  align-items: flex-end;
  justify-content: space-between;
  overflow-x: auto;
  padding: 0.25rem 0;
}
.ed-tabela__acoes svg {
  color: #0586D7;
}
.ed-tabela__acoes .ed-tabela__pesquisa {
  display: flex;
  align-items: center;
}
.ed-tabela__acoes .ed-tabela__pesquisa input {
  min-width: 7rem;
}
.ed-tabela__acoes .ed-tabela__filtros {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.ed-tabela__acoes .ed-tabela__filtros > * {
  margin-top: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas {
  display: flex;
  margin-right: 1rem;
  align-items: flex-end;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas > *:not(:first-child) {
  margin-left: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__datas .data {
  width: 7.5rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__status {
  margin-right: 1rem;
}
.ed-tabela__acoes .ed-tabela__filtros .ed-tabela__status select {
  min-width: 6.25rem;
}
.ed-tabela__acoes .ed-tabela__filtros p {
  margin: 0;
  color: #757575;
}
.ed-tabela__acoes .ed-tabela__filtros .add-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ed-tabela__acoes .ed-tabela__filtros .add-button svg {
  height: 38px;
  width: 38px;
}

.ed-tabela__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ed-tabela__pagination input {
  width: 3rem;
  text-align: center;
}

.clickable {
  cursor: pointer;
}

.ed-tabela__notFound {
  text-align: center;
  padding: 2rem;
}

@media screen and (max-width: 992px) {
  .ed-tabela__acoes {
    margin-bottom: 1rem;
  }

  .ed-tabela .hide-mobile {
    display: none;
  }
  .ed-tabela img {
    max-height: 3rem;
    max-width: 100%;
  }
  .ed-tabela td {
    text-overflow: ellipsis;
    max-width: 8rem;
    overflow: hidden;
    white-space: nowrap;
  }
}
.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.invalid {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-field .tox-tinymce {
  border-color: #dc3545;
}

.invalid-field .dropzone {
  border-color: #dc3545 !important;
}

.modalAviso .modal-header {
  background-color: #ffc107 !important;
  color: white;
}

.modalErro .modal-header {
  background-color: #dc3545 !important;
  color: white;
}

.modalFb .modal-content {
  border: none;
}

.modalFb .modal-header .modal-title {
  display: flex;
  align-items: center;
}

.modalFb .modal-header .modal-title svg {
  margin-right: 10px;
}

.title-control {
  display: flex;
  align-items: center;
}

.title-control svg {
  margin-left: 10px;
  color: #007bff;
}

.add_widget {
  width: 100%;
}

.form-group {
  scroll-margin: 100px 0 0 0;
}

.form-group.added {
  -webkit-animation: flash 2s;
          animation: flash 2s;
}

@-webkit-keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #ffc107;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #ffc107;
  }
  100% {
    background-color: transparent;
  }
}
.custom_modal.success .modal-header {
  background-color: #48C864;
}
.custom_modal.success .modal-header svg {
  color: #48C864;
}
.custom_modal.success .modal-body hr {
  border-top: 1px solid rgba(72, 200, 100, 0.5);
}
.custom_modal.success .modal-body button {
  color: #48C864;
}
.custom_modal.success .modal-body button:active {
  border: 1px solid #48C864;
  color: #48C864 !important;
  border-color: #48C864;
}
.custom_modal.success .custom_modal__options .line_divisoria {
  background-color: rgba(72, 200, 100, 0.5);
}

.custom_modal.warning .modal-header {
  background-color: #FFC75B;
}
.custom_modal.warning .modal-header svg {
  color: #FFC75B;
}
.custom_modal.warning .modal-body hr {
  border-top: 1px solid rgba(255, 199, 91, 0.5);
}
.custom_modal.warning .modal-body button {
  color: #FFC75B;
}
.custom_modal.warning .modal-body button:active {
  border: 1px solid #FFC75B;
  color: #FFC75B !important;
  border-color: #FFC75B;
}
.custom_modal.warning .custom_modal__options .line_divisoria {
  background-color: rgba(255, 199, 91, 0.5);
}

.custom_modal.error .modal-header {
  background-color: #FF6767;
}
.custom_modal.error .modal-header svg {
  color: #FF6767;
}
.custom_modal.error .modal-body hr {
  border-top: 1px solid rgba(255, 103, 103, 0.5);
}
.custom_modal.error .modal-body button {
  color: #FF6767;
}
.custom_modal.error .modal-body button:active {
  border: 1px solid #FF6767;
  color: #FF6767 !important;
  border-color: #FF6767;
}
.custom_modal.error .custom_modal__options .line_divisoria {
  background-color: rgba(255, 103, 103, 0.5);
}

.custom_modal .modal-content {
  border: none;
}

.custom_modal .modal-header {
  color: white;
  justify-content: flex-start;
  align-items: center;
}
.custom_modal .modal-header h1 {
  margin: 0;
}
.custom_modal .modal-header svg {
  background-color: white;
  border-radius: 50%;
  padding: 0.25rem;
  margin-right: 1rem;
}

.custom_modal .modal-body p {
  color: #838383;
  font-weight: 500;
}
.custom_modal .modal-body hr {
  width: 80%;
}
.custom_modal .modal-body button {
  background-color: transparent;
  border: none;
  display: block;
  margin: auto;
}
.custom_modal .modal-body button:active {
  background-color: transparent !important;
}

.custom_modal .custom_modal__options {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.custom_modal .custom_modal__options .line_divisoria {
  width: 1px;
  height: 30px;
}

.gray {
  color: #838383 !important;
}

.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.dark p {
  color: #B1B1B1 !important;
}

big-card p {
  color: #212529 !important;
}

.dark textarea {
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
}

.confirm {
  justify-content: flex-end;
  margin-top: 20px;
}

.confirm > * {
  margin-bottom: 15px;
}

.is-invalid .multiSelect__control {
  border: 1px solid;
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid .multiSelect__control:hover {
  border: 1px solid;
  border-color: #dc3545;
}

.trello-card {
  width: 400px;
  height: auto;
  display: flex;
  border: 1px solid #B1B1B1;
  background-color: white !important;
  color: #3B3B3B !important;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}
.trello-card .trello-nome {
  font-weight: 500;
  font-size: 16px;
}
.trello-card .trello-nomeLista {
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
}
.trello-card .trello-desc {
  font-size: 14px;
  text-align: justify;
  color: #3B3B3B !important;
}
.trello-card .trello-datas {
  width: 180px;
  font-size: 12px;
  margin-left: 15px;
}
.trello-card .tags {
  align-items: end;
  justify-content: start;
  display: flex;
  flex-direction: column;
  width: 120px;
}
.trello-card .tags div {
  margin-bottom: 5px;
}
.trello-card .tags .red {
  width: 100px;
  height: 33px;
  background-color: rgba(220, 53, 69, 0.3);
  padding: 0.25rem 0.75rem;
  color: #dc3545;
  border-radius: 15px;
}
.trello-card .tags .red::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
}
.trello-card .tags .yellow {
  width: 100px;
  height: 33px;
  background-color: rgba(206, 168, 2, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cea802;
  border-radius: 15px;
}
.trello-card .tags .yellow::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cea802;
}
.trello-card .tags .purple {
  width: 110px;
  height: 33px;
  background-color: rgba(128, 0, 128, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cf02cf;
  border-radius: 15px;
}
.trello-card .tags .purple::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cf02cf;
}
.trello-card .tags .green {
  width: auto;
  height: auto;
  background-color: rgba(0, 128, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: green;
  border-radius: 15px;
}
.trello-card .tags .green::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: green;
}
.trello-card .tags .orange {
  width: auto;
  height: auto;
  background-color: rgba(255, 165, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: orange;
  border-radius: 15px;
}
.trello-card .tags .orange::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: orange;
}
.trello-card .tags .blue {
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 255, 0.3);
  padding: 0.25rem 0.75rem;
  color: #5555fd;
  border-radius: 15px;
}
.trello-card .tags .blue::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555fd;
}

.none, .pesq {
  color: #3B3B3B;
  width: 100%;
  margin: 15px auto;
  text-align: center;
}

.dark {
  color: #B1B1B1;
}
.dark .none, .dark .pesq {
  color: #B1B1B1;
  width: 100%;
}
.dark textarea {
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
}
.dark .trello-card {
  width: 400px;
  height: auto;
  display: flex;
  border: 1px solid #B1B1B1;
  background-color: #3B3B3B !important;
  color: #B1B1B1 !important;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}
.dark .trello-card .trello-nome {
  font-weight: 500;
  font-size: 16px;
}
.dark .trello-card .trello-desc {
  font-size: 14px;
  text-align: justify;
  color: #B1B1B1 !important;
}
.dark .trello-card .trello-datas {
  width: 180px;
  font-size: 12px;
  margin-left: 15px;
}
.dark .trello-card .tags {
  align-items: end;
  justify-content: start;
  display: flex;
  flex-direction: column;
  width: 120px;
}
.dark .trello-card .tags div {
  margin-bottom: 5px;
}
.dark .trello-card .tags .red {
  width: 100px;
  height: 33px;
  background-color: rgba(220, 53, 69, 0.3);
  padding: 0.25rem 0.75rem;
  color: #dc3545;
  border-radius: 15px;
}
.dark .trello-card .tags .red::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #dc3545;
}
.dark .trello-card .tags .yellow {
  width: 100px;
  height: 33px;
  background-color: rgba(255, 208, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: #ffd000;
  border-radius: 15px;
}
.dark .trello-card .tags .yellow::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffd000;
}
.dark .trello-card .tags .purple {
  width: 110px;
  height: 33px;
  background-color: rgba(128, 0, 128, 0.3);
  padding: 0.25rem 0.75rem;
  color: #cf02cf;
  border-radius: 15px;
}
.dark .trello-card .tags .purple::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #cf02cf;
}
.dark .trello-card .tags .green {
  width: auto;
  height: auto;
  background-color: rgba(0, 128, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: green;
  border-radius: 15px;
}
.dark .trello-card .tags .green::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: green;
}
.dark .trello-card .tags .orange {
  width: auto;
  height: auto;
  background-color: rgba(255, 165, 0, 0.3);
  padding: 0.25rem 0.75rem;
  color: orange;
  border-radius: 15px;
}
.dark .trello-card .tags .orange::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: orange;
}
.dark .trello-card .tags .blue {
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 255, 0.3);
  padding: 0.25rem 0.75rem;
  color: #5555fd;
  border-radius: 15px;
}
.dark .trello-card .tags .blue::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #5555fd;
}

.aviso {
  font-weight: 400;
  font-size: 1.2rem;
  color: #dc3545;
  width: 100%;
  margin: 15px auto;
  text-align: center;
}
.aviso b {
  font-weight: 600;
}
